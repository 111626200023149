import { useGetProducts, useGetProductsByCategories } from "services/articles";
import { usePromise } from "services/index";
import { useGetProductsCategories } from "services/categories/index";
import { getTokenOrSignInByLicenceNumber, useGetTemplateData } from "services/licence";
import { jwtDecode } from "jwt-decode";
import { useContext, useState } from "react";
import { getTranslation, haveSubDomaine, TransformToNumber } from "utils";
import { useSettingsAppsDispatch, setLicenceAuth } from 'context/SettingsAppsContext';
import { useGlobalState } from "context/GlobalStateContext";
import errorTranslation from "template/global/translation/errorTranslation";  
import { ErrorAndLoadingContext } from "context/ErrorHandlingAndLoading";
/**
 * Custom hook that handles API failure scenarios.
 *
 * @returns {Object} - An object containing the `unauthorizedError` method.
 */
const useOnFailure = () => {
  const settingsAppsDispatch = useSettingsAppsDispatch();

  return {
    /**
     * Handles specific error codes and status codes.
     *
     * @param {Object} error - The error object from the API response.
     * @param {number} [error.status] - The HTTP status code of the error.
     * @param {Object} [error.data] - The data object inside the error response.
     * @param {string} [error.data.code] - The specific error code from the response data.
     * @param {string} [error.data.error] - Error message from the response data.
     */
    unauthorizedError: (error) => {
      if (error?.status > 499)
        window.location.replace(`${window.location.origin}/error/setting`);
console.log(error)
      if (['ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE', 'ECOMMERCE_NOT_ACTIVE', 'NO_TEMPLATE_CONFIGURED','ECOM_SUB_DOMAIN_NOT_FOUND'].includes(error?.data?.code || error?.data)) {
        if(!haveSubDomaine())
        setLicenceAuth(settingsAppsDispatch, { codeShop: localStorage.getItem("codeShop") , licenceData: { token: null ,
          documentCode:null,
          saleDocument:{lines:[]},} });
          localStorage.setItem("maxRefreshToken", 0);

        window.location.replace(`${window.location.origin}/error/setting${haveSubDomaine()?'':`/${localStorage.getItem("codeShop")}`}`)
      }

      if (error?.status === 401 && ["invalid_token"].includes(error?.data?.error)) {
        let maxRefreshToken = localStorage.getItem("maxRefreshToken");

        try {

          setLicenceAuth(settingsAppsDispatch, { codeShop: localStorage.getItem("codeShop"), licenceData: { token: null, 
            documentCode:null,
            saleDocument:{lines:[]},} });
          if (maxRefreshToken > 1) {
            localStorage.setItem("maxRefreshToken", 0);
           window.location.replace(`${window.location.origin}/error/setting${haveSubDomaine()?'':`/${localStorage.getItem("codeShop")}`}`)
          } else {
            localStorage.setItem("maxRefreshToken", TransformToNumber(maxRefreshToken) + 1);
            window.location.reload();
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  };
};

/**
 * Hook to fetch all products.
 *
 * @returns {Object} - An object containing the products and the request function.
 * @returns {Array} [allProducts] - Array of all products.
 * @returns {Function} getAllProducts - Function to initiate product fetching.
 */
export const useGetAllProducts = () => {
  const { result, request } = usePromise(useGetProducts, {
    onSuccess: (data) => {},
  });

  return {
    allProducts: result?.data,
    getAllProducts: request,
  };
};

/**
 * Hook to fetch products by categories.
 *
 * @returns {Object} - An object containing the products by categories and the request function.
 * @returns {Array} [productsByCategories] - Array of products by categories.
 * @returns {Function} getProductsByCategories - Function to initiate product fetching.
 */
export const useProductsByCategories = () => {
  const { result, request } = usePromise(useGetProductsByCategories, {});

  return {
    productsByCategories: result?.data,
    getProductsByCategories: request,
  };
};

/**
 * Hook to fetch all product categories from the API.
 *
 * @returns {Object} - An object containing product categories, the setter, and the request function.
 * @returns {Array} [allProductsCategories] - Array of all product categories.
 * @returns {Function} setAllProductsCategories - Setter for all product categories.
 * @returns {Function} getProductsCategories - Function to fetch the product categories.
 */
export const useGetProductsCategoriesApi = () => {
  const [allProductsCategories, setAllProductsCategories] = useState();
  const { unauthorizedError } = useOnFailure();
  const { request } = usePromise(useGetProductsCategories, {
    onSuccess: ({ data }) => {
      setAllProductsCategories(data || []);
    },
    onFailure: unauthorizedError,
  });

  return {
    allProductsCategories,
    setAllProductsCategories,
    getProductsCategories: request,
  };
};

/**
 * Hook to get a token by license number.
 *
 * @param {Function} callback - Callback function to execute after fetching the token.
 * @returns {Object} - Object containing the license data and the request function.
 * @returns {Object} [licenceData] - Decoded license data token.
 * @returns {Function} getTokenByLicenceNumber - Function to fetch the token.
 */
export const useGetTokenByLicenceNumber = (callback = () => null) => {
  const [licenceDataDataToken, setLicenceDataDataToken] = useState(null);
  const { unauthorizedError } = useOnFailure();
  const { request } = usePromise(getTokenOrSignInByLicenceNumber, {
    onSuccess: async (data) => {
      if (data?.data) {
        const decoded = await jwtDecode(data.data); 
        setLicenceDataDataToken({ ...decoded, token: data?.data });
        callback(1);
      }
    },
    onFailure: (error) => {
      unauthorizedError(error);
      callback();
    },
  });

  return {
    licenceData: licenceDataDataToken,
    getTokenByLicenceNumber: request,
  };
};

/**
 * Hook to fetch template data from the API.
 *
 * @param {Function} callback - Callback function to execute if an error occurs.
 * @returns {Object} - An object containing the template data and the request function.
 * @returns {Object} [templateData] - Fetched template data.
 * @returns {Function} setTemplateData - Setter for template data.
 * @returns {Function} getTemplateData - Function to fetch the template data.
 */
export const useGetTemplateDataApi = (callback = () => null) => {
  const [templateData, setTemplateData] = useState();

  const { request } = usePromise(useGetTemplateData, {
    onSuccess: ({ data }) => {
      if (data) setTemplateData(data);
    },
    onFailure: (error) => { 
        callback(['ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE', 'ECOMMERCE_NOT_ACTIVE', 'NO_TEMPLATE_CONFIGURED'].includes(error?.data?.code) || error?.status > 499);
    },
  });

  return {
    templateData,
    setTemplateData,
    getTemplateData: request,
  };
};

/**
 * Hook to get a token by signing in.
 *
 * @param {Function} callback - Callback function to execute after fetching the token.
 * @returns {Object} - Object containing the license data and the request function.
 * @returns {Object} [licenceData] - Decoded license data token.
 * @returns {Function} getTokenByLicenceNumber - Function to fetch the token.
 */
export const useGetTokenBySignIn = (callback = () => null) => {
  const [licenceDataDataToken, setLicenceDataDataToken] = useState(null);
  const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
  const {language, licenceData: { documentCode,saleDocument } } = useGlobalState(); 

  const { request } = usePromise(getTokenOrSignInByLicenceNumber, {
    onSuccess: async (data) => {
      if (data?.data) {
        const decoded = await jwtDecode(data.data);

        setLicenceDataDataToken({ ...decoded, token: data?.data ,documentCode ,saleDocument });
        callback(1);
      }
    },
    onFailure: (error) => {
      if(error.data.code ===  'ECOM_SUB_DOMAIN_NOT_FOUND')
        window.location.replace(`${window.location.origin}/error/setting`);

      loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'INVALID_EMAIL_OR_PASSWORD',errorTranslation), 'error');
 
      callback(null);
    },
  });

  return {
    licenceData: licenceDataDataToken,
    getTokenByLicenceNumber: request,
  };
};


export {};