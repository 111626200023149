import axios from 'axios' ;
import { useEffect, useContext,useReducer } from "react"; 
import { ErrorAndLoadingContext } from "context/ErrorHandlingAndLoading";
import { getTranslation } from 'utils'; 
import errorTranslation from "template/global/translation/errorTranslation";  
export const test_api = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json", 
  },
});
 
const api = axios.create({
  baseURL:process.env.REACT_APP_API_LINEOSOFT_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json", 
  },
});


api.interceptors.request.use(
  (config) => {
    const {codeShop,licencesData } = localStorage;
    let token = '';

    try {
      token = JSON.parse(licencesData)[codeShop]?.token; 
    } catch (error) { 
    }
   

   // const codeShop = localStorage.getItem("codeShop") || undefined;
     if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-LICENCE-CODE"] = codeShop; 
    }
    return config;
  },
  (error) => Promise.reject(error),
);

 
const states = {
  loading: "loading",
  failure: "failure",
  success: "success",
};

function reducer(state, action) {
  switch (action.type) {
    case states.loading:
      return {
        ...state,
        loading: true,
      };
    case states.success:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case states.failure:
      return {
        ...state,
        loading: false,
        error: action.result,
      };
    default:
      return state;
  }
}
export function usePromise(
  promiseFn,
  { onSuccess, onFailure, isEffect = false, effectCondition = [] } = {},
) { 

  const initialState = {
    loading: false,
    result: undefined,
    error: undefined,
  }; 

  const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
  const language = JSON.parse(localStorage.getItem("language")) || 'FRENCH';  
  const [{ error, result, loading }, dispatch] = useReducer(
    reducer,
    initialState,
  ); 
  const request = (...args) => {
    dispatch({ type: states.loading });
 
    !!args &&
      promiseFn(...args)
        .then(response => {
          dispatch({ type: states.success, result: response });
          onSuccess && onSuccess(response, args);
          localStorage.setItem("maxRefreshToken",0);  
        })
        .catch(error => {
          dispatch({ type: states.failure, result: error }); 
       
          var code = error?.data?.code ||  error?.response?.data?.code || null; 
             if ((code || []).includes('SMTP') && (code || []).includes('CANNOT_BE_NULL') && code.lenght > 20)
              code= "SMTP_?_CANNOT_BE_NULL"
//open,msg,severity
          if(code)
            loadingContextAndErrorContext.snackbar(true,
              getTranslation(
                language,
                code=== 'DUPLICATED_EMAIL' ? 'DUPLICATED_EMAIL' : 'OPERATION_FAILED'//  
                , errorTranslation
              ), 'error'
            ); 
if(!code && ![401,403].includes((error?.response || error)?.status) )
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_FAILED'), 'error'); 
          onFailure && onFailure(error?.response || error, args);  

        })
        .finally(() => {});
  };
  // eslint-disable-next-line
  useEffect(isEffect ? request : () => void 0, effectCondition);
  return {
    request,
    result,
    error,
    loading,
  };
}

export {api};
export default api;